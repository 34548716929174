<template>
  <div>
    <button @click="showAlert">Show Alert</button>

    <v-dialog v-model="dialog" max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="red-button">
          Скачать музыку
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">
          Подождите, пока архив загрузится
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false
  }),
  methods: {
    showAlert() {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.red-button {
  background-color: red;
  color: white;
}
</style>