<template>
  {{ school }}
</template>

<script>
export default {
  props: ['school'],
  data() {
    return {

    }
  }
}
</script>

<style>

</style>