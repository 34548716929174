<template>
    <p v-for="price in prices" :key="price"> {{ price }} <br /> </p>
</template>

<script>

import axios from 'axios'

export default {
    data() {
        return {
            data: []
        }
},
computed: {
    prices: function() {
        return this.data
    }
},
created() {
    this.getPriceConfirmeds()
},
methods: {
    async getPriceConfirmeds() {
        await axios.get(`${process.env.VUE_APP_DOMAIN}/api/price-confirmeds`)
        .then((res) => {
            this.data = res.data.data
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })
    }

}
}
</script>

<style>

</style>