<template>
    <form class="login-form">
        <h2 class="title-form_text">Кабинет организатора</h2>
        <div class="mb-3">
            <input type="text" class="form-control" placeholder="Логин" v-model="login" required>
        </div>
        <div class="mb-3">
            <input type="password" class="form-control" placeholder="Пароль" v-model="password" required>
        </div>
        <!-- <h3 class="error-format" v-if="error">{{ error }}</h3> -->
        <div class="d-flex justify-content-start">
            <ui-button type="submit" @click="auth" raised>Войти</ui-button>
        </div>
        <a href="#">Забыли пароль</a>
    </form>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            login: '',
            password: '',
            error: '',
        }
    },
    created() {
        if (localStorage.getItem('token')) this.$router.push('performance-list')
    },
    methods: {
        async auth() {
            if (!this.login) {
                this.$alert('Логин не заполнен')
                return
            }

            if (!this.password) {
                this.$alert('Пароль не заполнен')
                return
            } 

            var context = this;
            await axios.post(`${process.env.VUE_APP_DOMAIN}/api/admin-login`, {
                login: this.login,
                password: this.password
            })
                .then(res => {
                    console.log(res)
                    const token = res.data.token;
                    localStorage.setItem('token', token);
                    context.$store.state.token = token;
                    context.$router.push('/performance-list');
                })
                .catch(err => {
                    console.log(err)
                    this.$alert(err.response.data.message)
                })
        }
    }
}
</script>