<template>
    <div class="demo">
        <ui-tab-bar v-model="active">
            <ui-tab v-for="(tab, index) in tabs" :key="index">{{ tab.text }}</ui-tab>
        </ui-tab-bar>
    </div>
    <template v-if="active === 0">
        <h1 style="margin-top: 30px;">Подтвержденные</h1>
        <div class="photo-grid" style="margin-top: 30px;">
            <div v-for="item in coachesArr" :key="item.id">
                <h3>{{ item.name }} {{ item.surname }}</h3>
                <ui-button @click="deleteCoach(item.id)" raised>Удалить</ui-button>
            </div>
        </div>
    </template>
    <template v-if="active === 1">     
        <h1 style="margin-top: 30px;">Неподтвержденные</h1>
        <div class="photo-grid" style="margin-top: 30px;">
            <div v-for="item in customCoachesArr" :key="item.id">
                <h3 v-if="item.title">{{ item.title }}</h3>
                <ui-button v-if="item.title" @click="acceptCoach(item.id)" raised>Подтвердить</ui-button>
            </div>
        </div>
    </template>
</template>

<script>

import axios from 'axios'

const tabs = [
    {
        text: "Подтвержденные",
        icon: "favorite",
    },
    {
        text: "Неподтвержденные",
        icon: "phone",
    },
];

export default {
    data() {
        return {
            tabs,
            coachesArr: [],
            customCoachesArr: [],
            active: 0,
        }
    },
    mounted() {
        this.customCoaches();
        this.coaches()
    },
    methods: {
        customCoaches() {
            axios.get(`${process.env.VUE_APP_DOMAIN}/api/custom-coaches`)
                .then((res) => {
                    this.customCoachesArr = res.data
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        coaches() {
            axios.get(`${process.env.VUE_APP_DOMAIN}/api/coaches`)
                .then((res) => {
                    this.coachesArr = res.data
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteCoach(coach_id) {
            axios
                .post(`${process.env.VUE_APP_DOMAIN}/api/coach-delete`, {
                    coach_id: coach_id,
                })
                .then((res) => {
                    this.coaches()
                    this.customCoaches()
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        acceptCoach(custom_coach_id) {
            axios
                .post(`${process.env.VUE_APP_DOMAIN}/api/coach-accept`, {
                    custom_coach_id: custom_coach_id,
                })
                .then((res) => {
                    this.coaches()
                    this.customCoaches()
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    }
}
</script>

<style></style>