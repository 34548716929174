<template>
      <h1>Массовая рассылка</h1>
      <ui-form-field>
            <ui-radio v-model="selected" input-id="female" value="users"></ui-radio>
            <label for="female">Все пользователи</label>
      </ui-form-field>
      <ui-form-field>
            <ui-radio v-model="selected" input-id="male" value="usersWithPerformance"></ui-radio>
            <label for="male">Пользователи с выступлениями</label>
      </ui-form-field>
      <br />
      <ui-textfield v-model="message">Сообщение</ui-textfield>
      <ui-button @click="massMailVk" raised>Разослать</ui-button>
      <br>
      <h2 v-if="users.length" style="margin-top: 15px;">Колличество: {{ users.length }}</h2>
      <h2 v-else style="margin-top: 15px;">Выберите категорию</h2>
      <div style="margin-top: 30px;" v-for="user in users" :key="user.id">
            <h3>{{ user.name }}</h3>
            <h3>{{ user.surname }}</h3>
            <h3>{{ user.vk_id }}</h3>
            <hr>
      </div>
      <!-- {{ users }} -->
</template>

<script>
import axios from 'axios'

export default {
      data() {
            return {
                  message: '',
                  selected: '',
                  users: [],
            }
      },
      watch: {
            selected() {
                  const users = "users"
                  const usersWithPerformance = "usersWithPerformance"

                  if (this.selected === users) this.fetchUsers()
                  if (this.selected === usersWithPerformance) this.userWithPerformance()
            }
      },
      methods: {
            massMailVk() {
                  axios.post(`${process.env.VUE_APP_DOMAIN}/api/mass-mailing`, {
                        message: this.message,
                        data: this.users,
                   })
                  .then((res) => {
                        console.log(res)
                  })
                  .catch((err) => {
                        console.log(err)
                  })
            },
            userWithPerformance() {
                  axios.get(`${process.env.VUE_APP_DOMAIN}/api/performance/users`)
                        .then((res) => {
                              this.users = res.data
                              console.log(res)
                        })
                        .catch((err) => {
                              console.log(err)
                        })
            },
            fetchUsers() {
                  axios.get(`${process.env.VUE_APP_DOMAIN}/api/vk/users`)
                        .then((res) => {
                              this.users = res.data
                              console.log(res)
                        })
                        .catch((err) => {
                              console.log(err)
                        })
            },
      }
}
</script>

<style></style>