<template>
    <h3>Статус: {{ this.status }}</h3>
    <p></p>
    <template v-if="this.status === this.theEventIsActive">
        <ui-textfield input-type="textarea" rows="8" cols="40" v-model="message" outlined>
            Ваше сообщение...
        </ui-textfield>
        <p></p>
        <ui-list>
            <ui-item v-for="i in messages" :key="i" @click="message = i">
                <ui-item-text-content>{{ i }}</ui-item-text-content>
            </ui-item>
        </ui-list>
        <ui-button raised @click="pause">Приостановить</ui-button>
    </template>
    <ui-button raised v-else @click="activate">Начать</ui-button>
    <p></p>
</template>

<script>

const messages = [
    'Начать мероприятие',
    'Закончить мероприятие',
    'Приостановить мероприятие',
    'Мероприятие открыто',
    'Мероприятие закончено',
    'Мероприятие приостановлено по тех.причинам'
];

const theEventIsActive = 'Мероприятие активно'
const theEventIsPause = 'Мероприятие приостановленно'

import axios from 'axios'

export default {
    data() {
        return {
            messages,
            message: '',
            status: '',
            theEventIsActive,
            theEventIsPause,
        }
    },
    created() {
        this.appStatus()
    },
    methods: {
        async appStatus() {
            const url = `${process.env.VUE_APP_DOMAIN}/api/app-status`;

            await axios.get(url)
                .then((res) => {
                    if (res.data[0].status === 'active') this.status = this.theEventIsActive
                    if (res.data[0].status === 'disabled') this.status = this.theEventIsPause
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async activate() {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            };

            const url = `${process.env.VUE_APP_DOMAIN}/api/app-activated`;

            const data = {}

            await axios.post(url, data, config)
                .then((res) => {
                    this.message = ''
                    this.appStatus()
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async pause() {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            };

            const url = `${process.env.VUE_APP_DOMAIN}/api/app-pause`;

            const data = {
                message: this.message
            }

            await axios.post(url, data, config)
                .then((res) => {
                    this.appStatus()
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
}
</script>

<style></style>