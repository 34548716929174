import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'

import CoachView from '../views/CoachView.vue'
import ProjectileView from '../views/ProjectileView.vue'
import MarketingView from '../views/MarketingView.vue'
import PriceConfirmedView from '../views/PriceConfirmedView.vue'
import StatePanelView from '../views/StatePanelView.vue'
import PerformanceListView from '../views/PerformanceListView.vue'
import CostumSchoolView from '../views/CustomSchoolView.vue'
import CustomCoachView from '../views/CustomCoachView.vue'
import TaymingView from "@/views/TaymingView.vue";

const routes = [
    {
        path: '/performance-list',
        name: 'performanceList',
        component: PerformanceListView
    },
    {
        path: '/',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/schools',
        name: 'costumSchoolView',
        component: CostumSchoolView
    },
    // {
    //   path: '/performance-list',
    //   name: 'PerformanceList',
    //   component: PermormanceList
    // },
    {
        path: '/coaches',
        name: 'Coaches',
        component: CoachView
    },
    {
        path: '/projectiles',
        name: 'Projectiles',
        component: ProjectileView
    },
    {
        path: '/marketing',
        name: 'Marketing',
        component: MarketingView
    },
    {
        path: '/price-confirmed',
        name: 'PriceConfirmed',
        component: PriceConfirmedView,
    },
    {
        path: '/state-panel',
        name: 'StatePanelView',
        component: StatePanelView,
    },
    {
        path: '/custom-coaches',
        name: 'CustomCoachView',
        component: CustomCoachView
    },
    {
        path: '/tayming/:id',
        name: 'TaymingView',
        component: TaymingView,
        props: true
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

// const isAuthenticated = store.state.token;
// const isAuthenticated = localStorage.getItem('token')
// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && !token) next({ name: 'Login' })
//   else next()
// })
/* eslint-disable */
// router.beforeEach(async (to) => {
//   if (!isAuthenticated && to.name !== 'Login') {
//     return { name: 'Login' }
//   } 
// })

// router.beforeEach((to, from, next) => {
// console.log(to)
// console.log(from)
// console.log(next)
// console.log('отработка beforeEach ' + isAuthenticated)
// if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
// else next()
// })

export default router