<template>
  <td style="text-align: center; background-color: #008000; color: #fff;" v-if="status === 'Оплачен'">{{ status }}</td>
  <td style="text-align: center; background-color: #FFB02E; color: #000;" v-if="status === 'Ожидает оплаты'">{{ status }}</td>
  <td style="text-align: center; background-color: yellowgreen; color: #fff;" v-if="status === 'Подтверждён'">{{ status }}</td>
  <td style="text-align: center; background-color: #ff0000;" v-if="status === 'Отклонён'">{{ status }}</td>
  <td style="text-align: center; background-color: beige;" v-if="status === 'Ожидание'">{{ status }}</td>

</template>

<script>
export default {
    props: ['status'],
    data() {
        return {

        }
    }
}
</script>

<style>

</style>