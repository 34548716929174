import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BalmUI from 'balm-ui'; 
import BalmUIPlus from 'balm-ui-plus'; 
import 'balm-ui-css';
import Vuetify from 'vuetify'
const app = createApp(App);

app.use(BalmUI);
app.use(BalmUIPlus);
app.use(store);
app.use(router);
app.use(Vuetify);
app.mount('#app');

// createApp(App).use(store).use(router).mount('#app')


// import { createApp } from 'vue';
// import App from './App.vue';

// import BalmUI from 'balm-ui'; // Official Google Material Components
// import BalmUIPlus from 'balm-ui-plus'; // BalmJS Team Material Components
// import 'balm-ui-css';


// app.use(BalmUI);
// app.use(BalmUIPlus);

// app.mount('#app');