<template>
  <div class="demo">
    <ui-tab-bar v-model="active">
      <ui-tab v-for="(tab, index) in tabs" :key="index">{{ tab.text }}</ui-tab>
    </ui-tab-bar>
  </div>
  <template v-if="active === 0">
    <h1 style="margin-top: 30px;">Подтвержденные</h1>
    <div class="photo-grid" style="margin-top: 30px;">
      <div v-for="item in schools" :key="item.id">
        <h3>{{ item.title }}</h3>
        <ui-button @click="deleteSchool(item.id)" raised>Удалить</ui-button>
      </div>
    </div>
  </template>
  <template v-if="active === 1">
    <h1 style="margin-top: 30px;">Неподтвержденные</h1>
    <div class="photo-grid" style="margin-top: 30px;">
      <div v-for="item in custom_schools" :key="item.id">
        <h3>{{ item.title }}</h3>
        <ui-button @click="acceptSchool(item.id)" raised>Подтвердить</ui-button>
      </div>
    </div>
  </template>
</template> 

<script>
import SchooItem from "@/components/SchoolItem.vue";
import axios from "axios";

const tabs = [
  {
    text: "Подтвержденные",
    icon: "favorite",
  },
  {
    text: "Неподтвержденные",
    icon: "phone",
  },
];

export default {
  compontens: { SchooItem },
  data() {
    return {
      tabs,
      active: 0,
      custom_schools: [],
      schools: [],
    };
  },
  created() {
    this.fetchSchools();
  },
  methods: {
    fetchSchools() {
      axios
        .get(`${process.env.VUE_APP_DOMAIN}/api/schools`)
        .then((res) => {
          this.schools = res.data.schools;
          this.custom_schools = res.data.custom_school;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteSchool(school_id) {

      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      const url = `${process.env.VUE_APP_DOMAIN}/api/school-delete`;

      const data = {
        school_id: school_id
      }

      await axios.post(url, data, config)
        .then((res) => {
          console.log(res)
          this.fetchSchools()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    acceptSchool(custom_school_id) {
      axios
        .post(`${process.env.VUE_APP_DOMAIN}/api/school-accept`, {
          custom_school_id: custom_school_id,
        })
        .then((res) => {
          this.fetchSchools()
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>